<template>
  <div>
    <b-input-group>
      <b-form-input v-model="description"></b-form-input>
      <b-input-group-append>
        <b-button
          variant="info"
          :disabled="isLoading"
          @click="submit"
        >
          <i class="uil uil-edit-alt"></i>
        </b-button>
      </b-input-group-append>
    </b-input-group>

  </div>
</template>

<script>
export default {
  name: 'PermissionDescriptionForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      description: '',
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.description = val.description
      }
    },
  },
  created() {
    if (this.value) {
      this.description = this.value.description
    }
  },
  methods: {
    submit() {
      const form = {
        ...this.value,
        description: this.description,
      }
      this.$emit('submit', form)
    },
  },
}
</script>
